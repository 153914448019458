import React from "react";
import styled from "styled-components/macro";

import { HeaderMedium28, Text12 } from "../../../../Components/Text/Text";
import theme from "../../../../Consts/Theme";

// styled-components
const StyledContainer = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  width: 205px;
  height: 135px;
  padding: 11px 0;

  background-color: ${(props) => props.bgColor};
  border-radius: 30px;
  border: 1px solid #474850;

  @media (max-width: 992px) {
    height: 95px;
    width: 195px;
  }
`;

const StyledContentWrap = styled.div`
  margin-left: 10px;
  text-align: center;
  color: ${theme.gray1};

  p.content:first-child {
    font-size: 12px;
  }

  .numbers {
    font-family: "Inter";
    font-size: 55px;
    margin-bottom: 15px;
    margin-top: 20px;

    color: ${theme.gray1};
  }

  .content {
    line-height: 16px;
    color: ${theme.gray1};
    padding: 0 25px;
    font-size: 14px;
  }
  .numbers::after {
    content: counter(count);
    animation: counter 1s linear alternate;
    counter-reset: count ${(props) => props.numbers};
  }

  @keyframes counter {
    10% {
      counter-increment: count 1;
    }
    20% {
      counter-increment: count 2;
    }
    30% {
      counter-increment: count 3;
    }
    40% {
      counter-increment: count 4;
    }
    50% {
      counter-increment: count 5;
    }
    60% {
      counter-increment: count 6;
    }
    70% {
      counter-increment: count 7;
    }
    80% {
      counter-increment: count 8;
    }
    90% {
      counter-increment: count 9;
    }
    100% {
      counter-increment: count 10;
    }
  }

  @media (max-width: 992px) {
    p.content:first-child {
    font-size: 10px;
  }
    .numbers {
      background: linear-gradient(140deg, #7497ff 24.34%, #f3f3f5 96.45%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      height: 50px;
      font-weight: 200;
      font-size: 40px;
      margin-bottom: 0;
      margin-top: 0;
      display: flex;
      align-items:center ;
      justify-content: center;
    }
    .content {
      font-size: 10px;
      line-height: normal;

    }
  }
`;
const HeroCardNumbers = ({ numbers, content, bgColor, preHeading }) => {
  return (
    <StyledContainer bgColor={bgColor}>
      <StyledContentWrap numbers={numbers}>
        <Text12 className="content" fontWeight={500} color={theme.white}>
          {preHeading}
        </Text12>
        <HeaderMedium28 className="numbers" fontWeight={200}></HeaderMedium28>
        <Text12 className="content" fontWeight={500} color={theme.white}  dangerouslySetInnerHTML={{
            __html:  content,
          }}>
        </Text12>
      </StyledContentWrap>
    </StyledContainer>
  );
};

export default HeroCardNumbers;
